import { IconButton, Stack, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import GroupsIcon from "@mui/icons-material/Groups";
import { useTranslation } from "react-i18next";
import { getEmployeesOfGroup, selectingEmployeeList } from "api";
import { useState } from "react";
import { MembersGroup } from "../MembersGroup";
import { useAccess } from "hooks";

export const GroupsActions = ({
  groups,
  setValueForm,
  setGroupSelected,
  row,
}) => {
  const { t } = useTranslation(["settings"]["general"]);

  const { access } = useAccess();

  const [miembrosGrupo, setMiembrosGrupo] = useState([]);
  const [openMiembrosGrupo, setOpenMiembrosGrupo] = useState(false);

  const actionEdit = (row) => {
    const data = groups?.find((group) => group?._id === row?.id);

    if (data?.name.includes("Organization")) {
      setValueForm("one");
    }
    if (data?.name.includes("Employees")) {
      setValueForm("two");
    }
    setGroupSelected(data);
  };

  const actionMembers = async (row) => {
    try {
      const { data: members } = await getEmployeesOfGroup(row?.id);
      const { data: employees } = await selectingEmployeeList();

      const data = [];

      for (const item of employees) {
        if (members.includes(item.userId)) {
          data.push({
            id: item.userId,
            fullName: item.fullName,
            department: item.department === "" ? "NA" : item.department,
            position: item.position,
          });
        }
      }

      setMiembrosGrupo(data);
      setOpenMiembrosGrupo(true);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Stack direction="row" spacing={1}>
        {access(
          "/cereza-organization/settings/security-user-permissions-members"
        ) && (
          <Tooltip title={t("settings:Miembros")}>
            <IconButton onClick={() => actionMembers(row)}>
              <GroupsIcon />
            </IconButton>
          </Tooltip>
        )}
        {access("/cereza-organization/settings/security-groups") && (
          <Tooltip title={t("settings:Editar")}>
            <IconButton onClick={() => actionEdit(row)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        )}
      </Stack>

      {access(
        "/cereza-organization/settings/security-user-permissions-members"
      ) && (
        <MembersGroup
          miembrosGrupo={miembrosGrupo}
          setOpenMiembrosGrupo={setOpenMiembrosGrupo}
          openMiembrosGrupo={openMiembrosGrupo}
        />
      )}
    </>
  );
};
