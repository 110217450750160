import { useEffect, useState } from "react";
import { getFlowVacation, getHolidays, getCountries } from "api";
import {
  Box,
  Button,
  CircularProgress,
  DialogContent,
  Stack,
  TableCell,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { BasicTable, Toast, UIBootstrapDialog, UIButton } from "components";
import { Form, Formik } from "formik";
import { FormVacation } from "./FormVacation";
import { deleteFlowVacation, postFlowVacation, putFlowVacation } from "api";
import Swal from "sweetalert2";
import _ from "lodash";
import { useAccess } from "hooks";

export const VacationsAutorizations = ({ employees = [] }) => {
  const { t } = useTranslation();

  const [finish, setFinish] = useState(false);
  const [dataVacations, setDataVacation] = useState([]);
  const [countries, setCountries] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const [dataTable, setDataTable] = useState([]);
  const [flowVacation, setFlowVacation] = useState(null);
  const [open, setOpen] = useState(false);

  const { HasPermissions } = useAccess();
  const { canAdd, canChange, canDelete } = HasPermissions(
    "/cereza-organization/settings/vacations-flow",
  );

  const createDataTable = (data, countries) => {
    setDataTable(
      _.orderBy(
        _.map(data, ({ _id, title, country: countryId }) => {
          const country = countries.find((c) => c.value === countryId);

          return {
            id: _id,
            title,
            country: country.label,
          };
        }),
        [(row) => row.title.toLowerCase()],
        ["asc"],
      ),
    );
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const { data: dHolidays } = await getHolidays(null);
        const { data: dCountries } = await getCountries(false);
        const { data: dFlowVacation } = await getFlowVacation();

        const countriesValues = dCountries.map(({ _id, name }) => ({
          value: _id,
          label: name,
        }));

        setDataVacation(dFlowVacation);
        setCountries(countriesValues);
        setHolidays(
          dHolidays.map(({ _id, name }) => ({ value: _id, label: name })),
        );

        createDataTable(dFlowVacation, countriesValues);
        setFinish(true);
      } catch (e) {
        console.log(e);
      }
    };
    if (employees.length) {
      getData();
    }
  }, [employees]);

  const handleClick = (row) => {
    setFlowVacation(dataVacations.find((v) => v._id === row.id));
    setOpen(true);
  };

  const onSubmit = async (values) => {
    try {
      let message = "";

      if (values?._id) {
        const { status, data, ...r } = await putFlowVacation(values);

        if (status != 202) {
          updateData("update", data);
          message = t("organization:FlowSettingSuccessfullyUpdated");
        } else {
          message = data.message;
        }
      } else {
        const { data, ...r } = await postFlowVacation(values);
        updateData("add", data);
        message = t("organization:FlowSettingSuccessfullyCreated");
      }

      Toast.fire({
        icon: "success",
        title: message,
      });
    } catch (err) {
      Toast.fire({
        icon: "error",
        title: `${err?.response?.data?.message}`,
      });
    }
  };

  const deleteFlow = async (item) => {
    try {
      const r = await deleteFlowVacation(item);
      updateData("delete", item);
      Toast.fire({
        icon: "success",
        title: t("organization:FlowSettingSuccessfullyDeleted"),
      });
    } catch (err) {
      Toast.fire({
        icon: "error",
        title: `${err?.response?.data?.message}`,
      });
    }
  };

  const updateData = async (action, data) => {
    let newData = _.cloneDeep(dataVacations);

    switch (action) {
      case "add":
        newData.push(data);
        break;

      case "update":
        newData = _.unionBy([data], newData, "_id");
        break;

      case "delete":
        newData = newData.filter((o) => o._id != data._id);
        break;

      default:
        break;
    }

    setDataVacation(newData);
    createDataTable(newData, countries);
    setOpen(false);
  };

  return (
    <Box pl={2} pr={2} pb={2}>
      {finish ? (
        <>
          <Stack
            direction="row"
            spacing={2}
            mb={2}
            sx={{
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {canAdd && (
              <Button
                variant="contained"
                onClick={() => {
                  setFlowVacation({
                    title: "",
                    country: "",
                    flowSettings: {
                      authorizationMethod: "ONE",
                      hierarchicalOrder: false,
                      approvers: [],
                      maxHierarchyDepth: "",
                      finalAuthorizer: false,
                      authorizers: [],
                      waitingTime: "",
                      automaticAuthorized: false,
                      deliveryMethod: "BROADCAST",
                    },
                    extraRules: {
                      allowVacationAccrual: false,
                      maximumAccrualPeriodsDays: "",
                      allowExceedingBalance: false,
                      allowVacationPeriods: false,
                      numberPeriodsInYear: "",
                      numberConsecutiveDays: "",
                      accountForNonWorkingDays: false,
                      holidayCalendars: [],
                      accountForHolidays: false,
                      allowVacationBeforeTheEndYear: false,
                      allowVacationStartingFromWhichMonth: "",
                    },
                  });
                  setOpen(true);
                }}
              >
                {t("general:Nuevo")}
              </Button>
            )}
          </Stack>
          <BasicTable rows={dataTable} handleClick={handleClick}>
            <TableCell>{t("organization:NameRule")}</TableCell>
            <TableCell>{t("organization:Country")}</TableCell>
          </BasicTable>
        </>
      ) : (
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <CircularProgress />
        </Stack>
      )}
      <UIBootstrapDialog
        title={t("organization:SettingAutorizationFlow")}
        open={open}
        setOpen={setOpen}
      >
        <DialogContent>
          <Formik
            initialValues={flowVacation}
            onSubmit={onSubmit}
            enableReinitialize={true}
          >
            {(formik) => (
              <Form autoComplete="false">
                <FormVacation
                  formik={formik}
                  countries={countries}
                  holidays={holidays}
                  employees={employees}
                  canAdd={canAdd}
                  canChange={canChange}
                />
                <Stack
                  mt={2}
                  direction={{ xs: "column", sm: "row" }}
                  spacing={{ xs: 1, sm: 1, md: 1 }}
                >
                  {((canAdd && !formik.values?._id) ||
                    (canChange && formik.values._id)) && (
                    <UIButton
                      type="submit"
                      label={
                        formik.values?._id
                          ? t("general:Actualizar")
                          : t("general:Guardar")
                      }
                      loading={formik.isSubmitting}
                      fullWidth={false}
                    />
                  )}
                  {canDelete && (
                    <UIButton
                      label={t("general:Eliminar")}
                      onClick={() => {
                        Swal.fire({
                          title: t("general:Are"),
                          text: t("general:Youwon"),
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: "#3085d6",
                          cancelButtonColor: "#d33",
                          confirmButtonText: t("general:deleteit"),
                        }).then((result) => {
                          deleteFlow(formik.values);
                        });
                      }}
                      disabled={formik.isSubmitting}
                      fullWidth={false}
                    />
                  )}
                </Stack>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </UIBootstrapDialog>
    </Box>
  );
};
