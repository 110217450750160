import { Box, Grid, Typography } from "@mui/material";
import { Toast, UIButton, UIModal } from "components";
import { useTranslation } from "react-i18next";
import { putUserPermissions } from "api";
import { managerErrors } from "utils/functions";
import { Form, Formik } from "formik";
import { CheckBoxMenuOrganization } from "../components/CheckBoxMenuOrganization";
import { CheckBoxMenuEmployees } from "../components/CheckBoxMenuEmployees";
import {
  generateInitialValues,
  replaceCodenames,
  transformObjectForPermission,
} from "helpers";
import { OrganizationNavigation } from "../CreateGroups/OrganizationNavigation";
import { EmployeeNavigation } from "../CreateGroups/EmployeeNavigation";

export const AssignPermission = ({
  permisos,
  initialsValues,
  setInitialsValues,
  userPermissions,
  setUserPermissions,
  employeeSelected,
  openAsignarPermiso,
  setOpenAsignarPermiso,
}) => {
  const { t } = useTranslation(["settings"]["general"]);

  const initial = {
    permits: {
      ...generateInitialValues(EmployeeNavigation),
      ...generateInitialValues(OrganizationNavigation),
    },
  };

  const handleSubmit = async (values, { resetForm }) => {
    const data = transformObjectForPermission(values);
    delete data?.name;
    delete data?.description;
    delete data?.default;
    const payload = await replaceCodenames(data, permisos);

    try {
      await putUserPermissions(payload, userPermissions?._id);
      resetForm();
      setUserPermissions(null);
      setInitialsValues(initial);
      setOpenAsignarPermiso(false);

      Toast.fire({
        icon: "success",
        title: t("settings:PermissionsOK"),
      });
    } catch (err) {
      managerErrors(err?.response?.data?.message);
      console.error("Error submitting form:", err);
    }
  };

  return (
    <>
      <UIModal
        open={openAsignarPermiso}
        setOpen={setOpenAsignarPermiso}
        width="80%"
      >
        <Box p={4}>
          <Typography variant="h6">{t("settings:AsignarPermiso")}</Typography>
          <Typography variant="subtitle2">
            {`${employeeSelected?.fullName} - ${employeeSelected?.position}`}
          </Typography>
          <br />

          <Formik
            initialValues={initialsValues}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {(formik) => (
              <Form id="" autoComplete="off">
                <Grid container spacing={3}>
                  <Grid item={true} xs={12} md={6}>
                    <Typography variant="h6">
                      {t("settings:NavegaciónOrganization")}
                    </Typography>
                  </Grid>
                  <Grid item={true} xs={12} md={6}>
                    <Typography variant="h6">
                      {t("settings:NavegaciónEmployees")}
                    </Typography>
                  </Grid>
                  <Grid item={true} xs={12} md={6}>
                    <CheckBoxMenuOrganization formik={formik} />
                  </Grid>
                  <Grid item={true} xs={12} md={6}>
                    <CheckBoxMenuEmployees formik={formik} />
                  </Grid>
                </Grid>
                <Box
                  mt={2}
                  width="100%"
                  display="flex"
                  justifyContent="space-between"
                >
                  <Box display="flex" gap="1em">
                    <UIButton type="submit" label={t("general:Guardar")} />
                    <UIButton
                      label={t("general:Cancel")}
                      onClick={() => {
                        setOpenAsignarPermiso(false);
                        setInitialsValues(initial);
                      }}
                    />
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </UIModal>
    </>
  );
};
