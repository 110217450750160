import { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import {
  BasicTable,
  Toast,
  UIButton,
  UIRichText,
  UISelect,
  UITextField,
} from "components";
import { Box, Grid, Paper, Typography, TableCell } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getSkills, postSkills, putSkills } from "api";
import { managerErrors } from "utils/functions";
import { useDispatch, useSelector } from "react-redux";
import { generalSelect, setSkills } from "@redux/slices/general";

export const Skills = () => {
  const { t } = useTranslation(["organization"]["general"]);
  const dispatcher = useDispatch();

  const { skills } = useSelector(generalSelect);

  const [responseData, setResponseData] = useState([]);
  const [rows, setRows] = useState([]);
  const [updateSkill, setUpdateSkill] = useState(null);
  const [iValues, setIValues] = useState({
    name: "",
    type: "",
    description: "",
  });

  const handleClick = ({ id }) => {
    const skill = responseData.find((skill) => skill._id === id);
    const { createdAt, updatedAt, tenant, ...rest } = skill;
    setIValues(rest);
  };

  const handleSubmit = async (values) => {
    try {
      if (values?._id !== undefined) {
        const { data } = await putSkills(values._id, values);
        setUpdateSkill(data);
      } else {
        const { data } = await postSkills(values);
        setUpdateSkill(data);
      }
      Toast.fire({
        icon: "success",
        title: t("general:AcciónExitosa"),
      });
    } catch (error) {
      managerErrors(error?.response?.data?.message);
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchSkills = async () => {
      try {
        const { data } = await getSkills();
        setResponseData(data);
        const rows = data.map((row) => ({
          id: row._id,
          name: row.name,
          type: row.type,
        }));
        setRows(rows);

        if (skills?.length === 0 || !skills || skills === undefined) {
          const selectors = data.map((row) => ({
            value: row._id,
            label: row.name,
          }));
          dispatcher(setSkills(selectors));
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchSkills();
  }, [updateSkill]);

  return (
    <>
      <Paper>
        <Box p={4}>
          <Typography variant="h5">{t("organization:Skills")}</Typography>
          <br />
          <Typography variant="body2">
            {t("organization:SkillsDescription")}
          </Typography>
          <br />
          <Formik
            initialValues={iValues}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {(formik) => (
              <Form id="d" autoComplete="off">
                <Grid container spacing={2}>
                  <Grid item={true} xs={12} md={6}>
                    <UITextField
                      formik={formik}
                      label={t("organization:NombreDeLaSkill")}
                      name={"name"}
                      width={"100%"}
                      disabled={formik.isSubmitting}
                    />
                  </Grid>
                  <Grid item={true} xs={12} md={6}>
                    <UISelect
                      formik={formik}
                      label={t("organization:typeSkill")}
                      name={"type"}
                      options={[
                        { label: t("organization:SOFT"), value: "SOFT" },
                        { label: t("organization:HARD"), value: "HARD" },
                      ]}
                      disabled={formik.isSubmitting}
                    />
                  </Grid>
                  <Grid item={true} xs={12}>
                    <UIRichText
                      formik={formik}
                      label={t("organization:SkillDescription")}
                      name={"description"}
                      disabled={formik.isSubmitting}
                    />
                  </Grid>
                </Grid>
                <br />
                <Box
                  mt={2}
                  width="100%"
                  display="flex"
                  justifyContent="space-between"
                >
                  <Box display="flex" gap="1em">
                    <UIButton
                      type="submit"
                      label={
                        formik?.values?._id
                          ? t("general:Actualizar")
                          : t("general:Guardar")
                      }
                      loading={formik.isSubmitting}
                    />
                    <UIButton
                      label={t("general:restart")}
                      onClick={() =>
                        formik.setValues({
                          name: "",
                          type: "",
                          description: "",
                        })
                      }
                      disabled={formik.isSubmitting}
                    />
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
          <br />
          <hr />
          <br />
          <BasicTable rows={rows} handleClick={handleClick}>
            <TableCell>{t("organization:NombreDeLaSkill")}</TableCell>
            <TableCell>{t("organization:typeSkill")}</TableCell>
          </BasicTable>
        </Box>
      </Paper>
    </>
  );
};
