import { Box, Grid, Typography } from "@mui/material";
import { UIModal } from "components";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import { CheckBoxMenuOrganization } from "../components/CheckBoxMenuOrganization";
import { CheckBoxMenuEmployees } from "../components/CheckBoxMenuEmployees";

export const MixPermissions = ({
  open,
  setOpen,
  initialsValues,
  employeeSelected,
}) => {
  const { t } = useTranslation(["settings"]["general"]);

  return (
    <>
      <UIModal open={open} setOpen={setOpen} width="80%">
        <Box p={4}>
          <Typography variant="h6">{t("settings:MixPermisos")}</Typography>
          <Typography variant="subtitle2">
            {`${employeeSelected?.fullName} - ${employeeSelected?.position}`}
          </Typography>
          <br />

          <Formik
            initialValues={initialsValues}
            onSubmit={() => {}}
            enableReinitialize={true}
          >
            {(formik) => (
              <Form id="" autoComplete="off">
                <Grid container spacing={3}>
                  <Grid item={true} xs={12} md={6}>
                    <Typography variant="h6">
                      {t("settings:NavegaciónOrganization")}
                    </Typography>
                  </Grid>
                  <Grid item={true} xs={12} md={6}>
                    <Typography variant="h6">
                      {t("settings:NavegaciónEmployees")}
                    </Typography>
                  </Grid>
                  <Grid item={true} xs={12} md={6}>
                    <CheckBoxMenuOrganization formik={formik} />
                  </Grid>
                  <Grid item={true} xs={12} md={6}>
                    <CheckBoxMenuEmployees formik={formik} />
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Box>
      </UIModal>
    </>
  );
};
