import { Languages } from "./Languages";
import { Currencies } from "./Currencies";
import { Skills } from "./Skills";
import { Grid } from "@mui/material";
import { MenuNav } from "./MenuNav";

export const PageCatalogs = () => {
  return (
    <>
      <MenuNav ubication={"catalogs"} />
      <br />
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12} md={6}>
          <Skills />
        </Grid>
        <Grid item={true} xs={12} md={6}>
          <Grid container={true} spacing={2}>
            <Grid item={true}>
              <Currencies />
            </Grid>
            <Grid item={true}>
              <Languages />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
