import { Divider, Grid, Typography } from "@mui/material";
import {
  UITextFast,
  UISelectFast,
  UISwitchFast,
  UISelectMultipleFast,
} from "components";
import { useTranslation } from "react-i18next";

export const FormVacation = ({
  formik,
  countries,
  holidays,
  employees,
  canAdd,
  canChange,
}) => {
  const { t } = useTranslation();
  const disabled =
    (!canAdd && !formik.values._id) ||
    (!canChange && formik.values._id) ||
    formik.isSubmitting;

  const NOTIFICATIONS_METHODS = [
    { value: "BROADCAST", label: t("options:BROADCAST") },
    { value: "GROUP", label: t("options:GROUP") },
    { value: "CHAIN", label: t("options:CHAIN") },
  ];

  const AUTHORIZATION_METHOD_CHOICES = [
    { value: "ONE", label: t("options:ONE") },
    { value: "ALL", label: t("options:ALL") },
    { value: "MAJORITY", label: t("options:MAJORITY") },
  ];

  const AUTOMATIC_METHOD_CHOICES = [
    { value: "APPROVE", label: t("options:APPROVE") },
    { value: "REJECT", label: t("options:REJECT") },
  ];

  const disabledAllowVacationPeriods = (e) => {
    if (e.target.checked) {
      formik.setFieldValue("extraRules.allowVacationPeriods", false);
      formik.setFieldValue("extraRules.numberPeriodsInYear", "");
      formik.setFieldValue("extraRules.numberConsecutiveDays", "");
    }
  };

  return (
    <>
      <Grid container={true} spacing={2} mb={2}>
        <Grid item={true} xs={12} md={6}>
          <UITextFast
            label={t("organization:NameRule")}
            name="title"
            disabled={disabled}
          />
        </Grid>
        <Grid item={true} xs={12} md={4}>
          <UISelectFast
            label={t("organization:Country")}
            name="country"
            options={countries}
            required={true}
            disabled={disabled}
          />
        </Grid>
      </Grid>
      <Divider>{t("organization:Nivel1")}</Divider>
      <Grid container={true} spacing={2} mb={2} mt={1}>
        <Grid item={true} xs={12} md={4}>
          <UISelectFast
            label={t("organization:authorizationMethod")}
            name="flowSettings.authorizationMethod"
            options={AUTHORIZATION_METHOD_CHOICES}
            required={true}
            disabled={disabled}
          />
        </Grid>
        <Grid item={true} xs={12} md={4}>
          <UISwitchFast
            label={t("organization:hierarchicalOrder")}
            name={"flowSettings.hierarchicalOrder"}
            onChange={(e) => {
              if (e.target.checked) {
                formik.setFieldValue("flowSettings.approvers", []);
              } else {
                formik.setFieldValue("flowSettings.maxHierarchyDepth", "");
              }
              formik.setFieldValue(
                "flowSettings.hierarchicalOrder",
                e.target.checked,
              );
            }}
            disabled={disabled}
          />
        </Grid>
        <Grid item={true} xs={12} md={12}>
          <UISelectMultipleFast
            label={t("organization:approvers")}
            name="flowSettings.approvers"
            options={employees}
            required={!formik.values.flowSettings.hierarchicalOrder}
            disabled={disabled || formik.values.flowSettings.hierarchicalOrder}
          />
        </Grid>
      </Grid>
      <Divider>{t("organization:Nivel2")}</Divider>

      <Grid container={true} spacing={2} mb={2} mt={1}>
        <Grid item={true} xs={12} md={4}>
          <UITextFast
            type="number"
            label={t("organization:maxHierarchyDepth")}
            name="flowSettings.maxHierarchyDepth"
            required={formik.values.flowSettings.hierarchicalOrder}
            disabled={disabled || !formik.values.flowSettings.hierarchicalOrder}
            InputProps={{
              inputProps: {
                min: 1,
              },
            }}
          />
        </Grid>
        <Grid item={true} xs={12} md={4}>
          <UISwitchFast
            label={t("organization:finalAuthorizer")}
            name={"flowSettings.finalAuthorizer"}
            onChange={(e) => {
              if (!e.target.checked) {
                formik.setFieldValue("flowSettings.authorizers", []);
              }
              formik.setFieldValue(
                "flowSettings.finalAuthorizer",
                e.target.checked,
              );
            }}
            disabled={disabled}
          />
        </Grid>
        <Grid item={true} xs={12}>
          <UISelectMultipleFast
            label={t("organization:authorizers")}
            name={"flowSettings.authorizers"}
            options={employees}
            required={formik.values.flowSettings.finalAuthorizer}
            disabled={disabled || !formik.values.flowSettings.finalAuthorizer}
          />
        </Grid>
      </Grid>

      <Divider>{t("organization:configuracionesAdicionales")}</Divider>
      <Grid container={true} spacing={2} mb={2} mt={1}>
        <Grid item={true} xs={12} md={4}>
          <UISelectFast
            label={t("organization:sendNotificationMethod")}
            name="flowSettings.deliveryMethod"
            options={NOTIFICATIONS_METHODS}
            required={true}
            disabled={disabled}
          />
        </Grid>
      </Grid>
      <Grid container={true} spacing={2} mb={2} mt={1}>
        <Grid item={true} xs={12} md={3}>
          <UISwitchFast
            label={t("organization:automaticAuthorized")}
            name={"flowSettings.automaticAuthorized"}
            onChange={(e) => {
              if (!e.target.checked) {
                formik.setFieldValue("flowSettings.automaticMethod", null);
                formik.setFieldValue("flowSettings.waitingTime", "");
              }
              formik.setFieldValue(
                "flowSettings.automaticAuthorized",
                e.target.checked,
              );
            }}
            disabled={disabled}
          />
        </Grid>
        <Grid item={true} xs={12} md={4}>
          <UISelectFast
            label={t("organization:automaticMethod")}
            name="flowSettings.automaticMethod"
            options={AUTOMATIC_METHOD_CHOICES}
            required={formik.values.flowSettings.automaticAuthorized}
            disabled={
              disabled || !formik.values.flowSettings.automaticAuthorized
            }
          />
        </Grid>
        <Grid item={true} xs={12} md={4}>
          <UITextFast
            type="number"
            label={t("organization:waitingTime")}
            name="flowSettings.waitingTime"
            required={formik.values.flowSettings.automaticAuthorized}
            disabled={
              disabled || !formik.values.flowSettings.automaticAuthorized
            }
            InputProps={{
              inputProps: {
                min: 1,
              },
            }}
          />
        </Grid>
      </Grid>
      <Grid container={true} spacing={2} mb={2}>
        <Grid item={true} xs={12} md={4}>
          <UISwitchFast
            label={t("organization:AllowVacationAcrual")}
            name={"extraRules.allowVacationAccrual"}
            onChange={(e) => {
              if (!e.target.checked) {
                formik.setFieldValue(
                  "extraRules.maximumAccrualPeriodsDays",
                  "",
                );
              }

              disabledAllowVacationPeriods(e);

              formik.setFieldValue(
                "extraRules.allowVacationAccrual",
                e.target.checked,
              );
            }}
            disabled={disabled}
          />
        </Grid>
        <Grid item={true} xs={12} md={3}>
          <UITextFast
            type="number"
            label={t("organization:MaximumPeriods")}
            name="extraRules.maximumAccrualPeriodsDays"
            helperText={t("organization:MaximumPeriodsHelp")}
            InputProps={{
              inputProps: {
                min: 1,
              },
            }}
            required={formik.values.extraRules.allowVacationAccrual}
            disabled={
              disabled || !formik.values.extraRules.allowVacationAccrual
            }
          />
        </Grid>
      </Grid>
      <Grid container={true} spacing={2} mb={2}>
        <Grid item={true} xs={12} md={8}>
          <UISwitchFast
            label={t("organization:AllowExceedingBalance")}
            name={"extraRules.allowExceedingBalance"}
            onChange={(e) => {
              disabledAllowVacationPeriods(e);

              formik.setFieldValue(
                "extraRules.allowExceedingBalance",
                e.target.checked,
              );
            }}
            disabled={disabled}
          />
        </Grid>
      </Grid>
      <Grid container={true} spacing={2} mb={2}>
        <Grid item={true} xs={12} md={4}>
          <UISwitchFast
            label={t("organization:AllowVacationPeriods")}
            name={"extraRules.allowVacationPeriods"}
            onChange={(e) => {
              if (!e.target.checked) {
                formik.setFieldValue("extraRules.numberPeriodsInYear", "");
                formik.setFieldValue("extraRules.numberConsecutiveDays", "");
              }
              formik.setFieldValue(
                "extraRules.allowVacationPeriods",
                e.target.checked,
              );
            }}
            disabled={
              disabled ||
              formik.values.extraRules.allowVacationAccrual ||
              formik.values.extraRules.allowExceedingBalance
            }
          />
        </Grid>
        <Grid item={true} xs={12} md={3}>
          <UITextFast
            type="number"
            label={t("organization:PeriodsPerYear")}
            name="extraRules.numberPeriodsInYear"
            InputProps={{
              inputProps: {
                min: 1,
              },
            }}
            required={formik.values.extraRules.allowVacationPeriods}
            disabled={
              disabled || !formik.values.extraRules.allowVacationPeriods
            }
          />
        </Grid>
        <Grid item={true} xs={12} md={3}>
          <UITextFast
            type="number"
            label={t("organization:NumberConsecutiveDays")}
            name="extraRules.numberConsecutiveDays"
            InputProps={{
              inputProps: {
                min: 1,
              },
            }}
            required={formik.values.extraRules.allowVacationPeriods}
            disabled={
              disabled || !formik.values.extraRules.allowVacationPeriods
            }
          />
        </Grid>
      </Grid>
      <Grid container={true} spacing={2} mb={2}>
        <Grid item={true} xs={12} md={4}>
          <UISwitchFast
            label={t("organization:AccountForNonWorkingDays")}
            name={"extraRules.accountForNonWorkingDays"}
            disabled={disabled}
          />
        </Grid>
      </Grid>
      <Grid container={true} spacing={2} mb={2}>
        <Grid item={true} xs={12} md={4}>
          <UISwitchFast
            label={t("organization:AccountForHolidays")}
            name={"extraRules.accountForHolidays"}
            disabled={disabled}
          />
        </Grid>
        <Grid item={true} xs={12} md={8}>
          <UISelectMultipleFast
            label={t("organization:HolidayCalendars")}
            name="extraRules.holidayCalendars"
            multiple={true}
            options={holidays}
            disabled={disabled}
            required={formik.values.extraRules.accountForHolidays}
          />
        </Grid>
      </Grid>
      <Grid container={true} spacing={2} mb={2}>
        <Grid item={true} xs={12} md={4}>
          <UISwitchFast
            label={t("organization:AllowVacationBeforeFirstYear")}
            name={"extraRules.allowVacationBeforeTheEndYear"}
            onChange={(e) => {
              if (!e.target.checked) {
                formik.setFieldValue(
                  "extraRules.allowVacationStartingFromWhichMonth",
                  "",
                );
              }
              formik.setFieldValue(
                "extraRules.allowVacationBeforeTheEndYear",
                e.target.checked,
              );
            }}
            disabled={disabled}
          />
        </Grid>
        <Grid item={true} xs={12} md={4}>
          <UITextFast
            type="number"
            label={t("organization:VacationFromMonth")}
            helperText={t("Mes")}
            name={"extraRules.allowVacationStartingFromWhichMonth"}
            InputProps={{
              inputProps: {
                min: 1,
              },
            }}
            required={formik.values.extraRules.allowVacationBeforeTheEndYear}
            disabled={
              disabled ||
              !formik.values.extraRules.allowVacationBeforeTheEndYear
            }
          />
        </Grid>
      </Grid>
    </>
  );
};
