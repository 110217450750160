import { useTranslation } from "react-i18next";
import { BasicTable } from "../../../../components";
import { Box, Grid, TableCell, Tabs, Tab } from "@mui/material";
import { Organization } from "./Organization";
import { Employee } from "./Employee";
import { parameterizationSelect } from "../../../../../redux/slices/parameterization";
import { useSelector } from "react-redux";
import { useAccess } from "hooks";

export const CreateGroups = ({
  groups,
  setUpdate,
  update,
  permisos,
  setValueForm,
  valueForm,
  setGroupSelected,
  groupSelected,
}) => {
  const { t } = useTranslation(["settings"]["general"]);

  const { HasPermissions } = useAccess();
  const { canAdd, canChange, canDelete } = HasPermissions(
    "/cereza-organization/settings/security-groups"
  );

  const { mode, darkBackgroundColor } = useSelector(parameterizationSelect);

  const handleChangeForm = (event, newValue) => {
    setValueForm(newValue);
  };

  return (
    <>
      <Grid container spacing={3} justifyContent="center">
        <Grid item={true} xs={12} md={10}>
          <BasicTable
            rows={groups?.dataTable}
            handleClick={() => {}}
            hover={false}
          >
            <TableCell>{t("settings:NombreDelGrupo")}</TableCell>
            <TableCell>{t("settings:DescripciónDelGrupo")}</TableCell>
            <TableCell>{t("settings:NumeroDePermisos")}</TableCell>
            <TableCell>{t("settings:Acciones")}</TableCell>
          </BasicTable>
        </Grid>
      </Grid>
      <br />
      <Box
        p={4}
        sx={{
          backgroundColor: mode === "light" ? "#f3f6f9" : darkBackgroundColor,
          borderRadius: "1rem",
        }}
      >
        <Tabs value={valueForm} onChange={handleChangeForm}>
          <Tab
            value="one"
            label={t("settings:Organization")}
            onClick={() => setGroupSelected(null)}
          />
          <Tab
            value="two"
            label={t("settings:Employee")}
            onClick={() => setGroupSelected(null)}
          />
        </Tabs>
        <br />
        {valueForm === "one" && (
          <Organization
            setUpdate={setUpdate}
            update={update}
            groupSelected={groupSelected}
            setGroupSelected={setGroupSelected}
            permisos={permisos}
            canAdd={canAdd}
            canChange={canChange}
            canDelete={canDelete}
          />
        )}
        {valueForm === "two" && (
          <Employee
            setUpdate={setUpdate}
            update={update}
            groupSelected={groupSelected}
            setGroupSelected={setGroupSelected}
            permisos={permisos}
            canAdd={canAdd}
            canChange={canChange}
            canDelete={canDelete}
          />
        )}
      </Box>
    </>
  );
};
