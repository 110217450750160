import { useState } from "react";
import { Grid, TableCell, Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { BasicTable, SearchBar, UIModal } from "../../../../components";
import { parameterizationSelect } from "../../../../../redux/slices/parameterization";
import { useSelector } from "react-redux";

export const MembersGroup = ({
  miembrosGrupo,
  openMiembrosGrupo,
  setOpenMiembrosGrupo,
}) => {
  const { t } = useTranslation(["settings"]["general"]);
  const [filteredData, setFilteredData] = useState([]);

  const { mainColor } = useSelector(parameterizationSelect);

  return (
    <UIModal open={openMiembrosGrupo} setOpen={setOpenMiembrosGrupo}>
      <Box p={4}>
        <Typography variant="h6">
          {t("settings:EmpleadosRelacionados")}
        </Typography>
        <br />
        <Grid container spacing={3}>
          <Grid item={true} xs={12}>
            <SearchBar
              data={miembrosGrupo}
              searchKeys={["fullName"]}
              setFilteredData={setFilteredData}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <BasicTable
              rows={filteredData.length === 0 ? miembrosGrupo : filteredData}
              handleClick={() => {}}
              PerPage={10}
            >
              <TableCell>{t("employee:fullName")}</TableCell>
              <TableCell>{t("employee:department")}</TableCell>
              <TableCell>{t("employee:position")}</TableCell>
            </BasicTable>
          </Grid>
        </Grid>
      </Box>
    </UIModal>
  );
};
