import { useTranslation } from "react-i18next";
import { Box, Tabs, Tab, Grid, TableCell, Paper } from "@mui/material";
import { useEffect, useState } from "react";
import { getPermissions, getPermissionsGroup } from "api";
import { selectingEmployeeList } from "api";
import { CreateGroups } from "./CreateGroups";
import { MenuNav } from "../MenuNav";
import SelectingTable from "components/Table/SelectingTable";
import { GroupsActions, UserActions } from "./components";
import { BasicTable, SearchBar } from "components";
import { useAccess } from "hooks";

export const Settings = () => {
  const { t } = useTranslation(["settings"]["employee"]);

  const { access } = useAccess();

  const [dataEmployees, setDataEmployees] = useState([]);
  const [value, setValue] = useState("one");
  const [groups, setGroups] = useState({ dataTable: [], getGroups: [] });
  const [update, setUpdate] = useState(null);
  const [permisos, setPermisos] = useState([]);
  const [selected, setSelected] = useState([]);
  const [table, setTable] = useState([]);

  const [groupSelected, setGroupSelected] = useState(null);
  const [valueForm, setValueForm] = useState("one");

  const [filteredData, setFilteredData] = useState(dataEmployees);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await getPermissions();
        const datTable = data.map((obj) => ({
          id: obj?._id,
          collection: obj?.collection,
          name: obj?.name,
          codename: obj?.codename,
        }));
        setTable(datTable);
        setPermisos(data);
      } catch (err) {
        console.error("Error getting employment history:", err);
      }
    };

    const lista = async () => {
      try {
        const { data } = await selectingEmployeeList();
        const rows = data.map((item) => ({
          id: item.userId,
          fullName: item.fullName,
          department: item.department === "" ? "NA" : item.department,
          position: item.position,
          acciones: (
            <UserActions
              row={{
                id: item.userId,
                fullName: item.fullName,
                department: item.department === "" ? "NA" : item.department,
                position: item.position,
              }}
            />
          ),
        }));
        setDataEmployees(rows);
      } catch (error) {
        console.error(error);
      }
    };

    const getGrupos = async () => {
      try {
        const { data } = await getPermissionsGroup();

        const rows = data.map((item) => ({
          id: item._id,
          name: item.name,
          description: item.description,
          permissions: item.permits.length.toString(),
          acciones: (
            <GroupsActions
              groups={data}
              setValueForm={setValueForm}
              setGroupSelected={setGroupSelected}
              row={{
                id: item._id,
                name: item.name,
                description: item.description,
                permissions: item.permits.length.toString(),
              }}
            />
          ),
        }));

        setGroups({ dataTable: rows, getGroups: data });
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
    lista();
    getGrupos();
  }, [update]);

  return (
    <>
      <Paper>
        <MenuNav ubication={"Security"} />
        <Box p={2}>
          <Box sx={{ width: "100%", display: "grid", placeItems: "center" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              style={{ maxWidth: "100%" }}
            >
              {access(
                "/cereza-organization/settings/security",
                "/cereza-organization/settings/security-user-groups",
                "/cereza-organization/settings/security-user-permissions",
                "/cereza-organization/settings/security-user-permissions-mix",
              ) && <Tab value="one" label={t("settings:Seguridadusuarios")} />}
              {access(
                "/cereza-organization/settings/security-groups",
                "/cereza-organization/settings/security-user-permissions-members",
              ) && <Tab value="two" label={t("settings:GruposDePermisos")} />}
              //! Este Tab comentado es para el desarrollador visualizar de una
              //! manera comoda los codename y relacionarlos a su desarrollo
              {/* <Tab value="three" label={t("settings:Codenames")} /> */}
            </Tabs>
          </Box>
          <br />

          {value === "one" &&
            access(
              "/cereza-organization/settings/security",
              "/cereza-organization/settings/security-user-groups",
              "/cereza-organization/settings/security-user-permissions",
              "/cereza-organization/settings/security-user-permissions-mix",
            ) && (
              <>
                <Grid container spacing={3} justifyContent="center">
                  <Grid item={true} xs={12} md={10}>
                    <SearchBar
                      data={dataEmployees}
                      searchKeys={["fullName"]}
                      setFilteredData={setFilteredData}
                    />
                  </Grid>
                  <Grid item={true} xs={12} md={10}>
                    <BasicTable
                      rows={
                        filteredData.length > 0 ? filteredData : dataEmployees
                      }
                      handleClick={() => {}}
                      hover={false}
                      PerPage={10}
                    >
                      <TableCell>{t("employee:fullName")}</TableCell>
                      <TableCell>{t("employee:department")}</TableCell>
                      <TableCell>{t("employee:position")}</TableCell>
                      <TableCell>{t("settings:Acciones")}</TableCell>
                    </BasicTable>
                  </Grid>
                </Grid>
              </>
            )}

          {value === "two" &&
            access(
              "/cereza-organization/settings/security-groups",
              "/cereza-organization/settings/security-user-permissions-members",
            ) && (
              <CreateGroups
                groups={groups}
                setUpdate={setUpdate}
                update={update}
                permisos={permisos}
                setValueForm={setValueForm}
                valueForm={valueForm}
                setGroupSelected={setGroupSelected}
                groupSelected={groupSelected}
              />
            )}

          {value === "three" && (
            <SelectingTable
              headCells={[
                {
                  id: "collection",
                  numeric: false,
                  disablePadding: true,
                  label: "colecciones",
                },
                {
                  id: "name",
                  numeric: false,
                  disablePadding: true,
                  label: t("employee:fullName"),
                },
                {
                  id: "codename",
                  numeric: false,
                  disablePadding: true,
                  label: "codename",
                },
              ]}
              rows={table}
              name={"permissions"}
              selected={selected}
              setSelected={setSelected}
              perPage={300}
            />
          )}
        </Box>
      </Paper>
    </>
  );
};
