import { IconButton, Stack, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import CategoryIcon from "@mui/icons-material/Category";
import { useTranslation } from "react-i18next";
import {
  getAccessApps,
  getPermissions,
  getPermissionsGroup,
  getUserPermissions,
  getUserPermissionsGroups,
} from "api";
import { managerErrors } from "utils/functions";
import { useEffect, useState } from "react";
import { AccessApps } from "../AccessApps";
import { AssignGroup } from "../AssignGroups";
import {
  generateInitialValues,
  reverseTransformObject,
} from "helpers";
import { EmployeeNavigation } from "../CreateGroups/EmployeeNavigation";
import { OrganizationNavigation } from "../CreateGroups/OrganizationNavigation";
import { AssignPermission } from "../AssignPermissions";
import { MixPermissions } from "../MixPermissions";
import Swal from "sweetalert2";
import { useAccess } from "hooks";

function fusionarPermisos(gruposDePermisos) {
  let permisosFusionados = {
    permits: {},
  };

  gruposDePermisos.forEach((grupo) => {
    Object.entries(grupo.permits).forEach(([path, permiso]) => {
      if (!permisosFusionados.permits[path]) {
        permisosFusionados.permits[path] = { ...permiso };
      } else {
        Object.entries(permiso.permissions).forEach(([key, value]) => {
          if (!permisosFusionados.permits[path].permissions[key]) {
            permisosFusionados.permits[path].permissions[key] = value;
          } else {
            permisosFusionados.permits[path].permissions[key] =
              value || permisosFusionados.permits[path].permissions[key];
          }
        });

        permisosFusionados.permits[path].path =
          permiso.path || permisosFusionados.permits[path].path;
      }
    });
  });

  return permisosFusionados;
}

export const UserActions = ({ row }) => {
  const { t } = useTranslation(["settings"]["general"]);

  const { access } = useAccess();

  const initial = {
    permits: {
      ...generateInitialValues(EmployeeNavigation),
      ...generateInitialValues(OrganizationNavigation),
    },
  };

  const [open, setOpen] = useState(false);
  const [employeeSelected, setEmployeeSelected] = useState(null);
  const [dataEmployeeSelected, setDataEmployeeSelected] = useState({});

  const [openAssignGroup, setOpenAssignGroup] = useState(false);
  const [selected, setSelected] = useState([]);
  const [getGroups, setGetGroups] = useState(null);

  const [openAsignarPermiso, setOpenAsignarPermiso] = useState(false);
  const [userPermissions, setUserPermissions] = useState(null);
  const [initialsValues, setInitialsValues] = useState(initial);
  const [permisos, setPermisos] = useState([]);

  const [openMix, setOpenMix] = useState(false);
  const [initialsValuesMix, setInitialsValuesMix] = useState(initial);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await getPermissions();
        setPermisos(data);
      } catch (err) {
        console.error("Error getting employment history:", err);
      }
    };
    fetchData();
  }, []);

  const ActionAccess = async (row) => {
    try {
      const { data } = await getAccessApps();
      setDataEmployeeSelected(data[row?.id]);
    } catch (err) {
      managerErrors(err?.response?.data?.message);
      console.error("Error submitting form:", err);
    }
    setEmployeeSelected(row);
    setOpen(true);
  };

  const ActionGroups = (row) => {
    const getGrupos = async () => {
      try {
        const { data } = await getUserPermissionsGroups(row?.id);
        setSelected(data?.groups ? data?.groups : []);
        setGetGroups(data);
      } catch (error) {
        console.error(error);
      }
    };
    getGrupos();
    setEmployeeSelected(row);
    setOpenAssignGroup(true);
  };

  const ActionPermits = async (row) => {
    setInitialsValues(initial);
    setEmployeeSelected(row);

    let timerInterval;
    Swal.fire({
      title: t("settings:ProcesandoPetición"),
      html: `${t("settings:EstaráListoEn")} <b></b> ${t(
        "settings:millisegundos"
      )} `,
      timer: 1200,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        const timer = Swal.getPopup().querySelector("b");
        timerInterval = setInterval(() => {
          timer.textContent = `${Swal.getTimerLeft()}`;
        }, 100);
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    });

    const fetchData = async () => {
      try {
        const { data } = await getUserPermissions(row?.id);
        setUserPermissions(data);
        if (data?.permits.length > 0) {
          const backresponse = {
            permits: data.permits,
          };
          const ValuesBack = {
            permits: reverseTransformObject(backresponse, initial, permisos)
              ?.permits,
          };
          setInitialsValues(ValuesBack);
        } else {
          setInitialsValues(initial);
        }
        setOpenAsignarPermiso(true);
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: t("settings:SomethingWentWrong"),
        });
        console.error(error);
      }
    };
    await fetchData();
  };

  const handleClick = async (row) => {
    setInitialsValuesMix(initial);
    setEmployeeSelected(row);

    let timerInterval;
    Swal.fire({
      title: t("settings:ProcesandoPetición"),
      html: `${t("settings:EstaráListoEn")} <b></b> ${t(
        "settings:millisegundos"
      )} `,
      timer: 2000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        const timer = Swal.getPopup().querySelector("b");
        timerInterval = setInterval(() => {
          timer.textContent = `${Swal.getTimerLeft()}`;
        }, 100);
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    });

    const getAllGrupos = async () => {
      try {
        const { data: allGroups } = await getPermissionsGroup();
        const { data: hisGroups } = await getUserPermissionsGroups(row?.id);
        const { data: userPerm } = await getUserPermissions(row?.id);

        const backresponse = {
          permits: userPerm?.permits,
        };

        const dataHisGroups = await allGroups.filter((objeto) =>
          hisGroups?.groups.includes(objeto._id)
        );

        const withFormat = await dataHisGroups.map((objeto) => {
          const backresponse = {
            permits: objeto.permits,
          };
          return {
            permits: reverseTransformObject(backresponse, initial, permisos)
              ?.permits,
          };
        });

        const individuales = {
          permits: reverseTransformObject(backresponse, initial, permisos)
            ?.permits,
        };

        const nuevoArray = [...withFormat, individuales];
        setInitialsValuesMix(fusionarPermisos(nuevoArray));

        setOpenMix(true);
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: t("settings:SomethingWentWrong"),
        });
        console.error(error);
      }
    };
    await getAllGrupos();
  };

  return (
    <>
      <Stack direction="row" spacing={1}>
        {access("/cereza-organization/settings/security") && (
          <Tooltip title={t("settings:Aplicaciones")}>
            <IconButton onClick={() => ActionAccess(row)}>
              <AdminPanelSettingsIcon />
            </IconButton>
          </Tooltip>
        )}

        {access("/cereza-organization/settings/security-user-groups") && (
          <Tooltip title={t("settings:AsignarGrupo")}>
            <IconButton onClick={() => ActionGroups(row)}>
              <CategoryIcon />
            </IconButton>
          </Tooltip>
        )}

        {access("/cereza-organization/settings/security-user-permissions") && (
          <Tooltip title={t("settings:AsignarPermiso")}>
            <IconButton onClick={() => ActionPermits(row)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        )}

        {access(
          "/cereza-organization/settings/security-user-permissions-mix"
        ) && (
          <Tooltip title={t("settings:MixPermisos")}>
            <IconButton onClick={() => handleClick(row)}>
              <AccountTreeIcon />
            </IconButton>
          </Tooltip>
        )}
      </Stack>

      {access("/cereza-organization/settings/security") && (
        <AccessApps
          open={open}
          setOpen={setOpen}
          employeeSelected={employeeSelected}
          dataEmployeeSelected={dataEmployeeSelected}
        />
      )}

      {access("/cereza-organization/settings/security-user-groups") && (
        <AssignGroup
          employeeSelected={employeeSelected}
          getGroups={getGroups}
          selected={selected}
          setSelected={setSelected}
          openAssignGroup={openAssignGroup}
          setOpenAssignGroup={setOpenAssignGroup}
        />
      )}

      {access("/cereza-organization/settings/security-user-permissions") && (
        <AssignPermission
          permisos={permisos}
          initialsValues={initialsValues}
          setInitialsValues={setInitialsValues}
          userPermissions={userPermissions}
          setUserPermissions={setUserPermissions}
          employeeSelected={employeeSelected}
          openAsignarPermiso={openAsignarPermiso}
          setOpenAsignarPermiso={setOpenAsignarPermiso}
        />
      )}

      {access(
        "/cereza-organization/settings/security-user-permissions-mix"
      ) && (
        <MixPermissions
          open={openMix}
          setOpen={setOpenMix}
          initialsValues={initialsValuesMix}
          employeeSelected={employeeSelected}
        />
      )}
    </>
  );
};
